<svelte:options tag="fds-tippy"></svelte:options>
<script>
  import {beforeUpdate,onMount } from 'svelte';
  import tippy from './index';
  import { followCursor } from 'tippy.js';
  import {stylestr} from './styles';
  import fdsHelper from "@fds-components/fds-helper"
  import {name, version as componentversion} from '../package.json';
  const path = fdsHelper.get_href();

  let stylestring = stylestr;
  let domelement = null;
  let styleel  = null;
  let initialized = false;

  /**
   * Get information about component
   * @param  {("api" | "examples" | "css")} type the info type
   */
  export async function getInfo(type) {
      if (type==="version"){
          return new Promise(resolve => {
              resolve(componentversion);
          });
      }
      let res = await fdsHelper.getInfo(type,name);
      return res;
  }

  /**
   * version of component
   * @type {string}
   */
  export const version = componentversion;
  /**
   * Reference to element, or elements the popup is connected see:The first argument you pass to tippy() https://atomiks.github.io/tippyjs/v6/constructor/
   * @type {HTMLElement}
   */

  export let reference = null;
  /**
   * the html content of tippy
   * @type {string}
   */
  export let content = null;
  /**
   * tippy instance reference to use in api should be get in little delay like:setTimeout(()=>widget3.instance.show(),30); https://atomiks.github.io/tippyjs/v6/methods/
   * @type {object}
   */
  export let instance = null;

  /**
   * [Configuration options for tippy](https://atomiks.github.io/tippyjs/v6/all-props/)
   * @type {{allowHTML: boolean, plugins: FollowCursor[], appendTo: string, content: string}}
   */
 export let props = {
    appendTo: 'parent',
    content: '',
    allowHTML: true,
    plugins: [followCursor],

  };

  $: if(content) {
      props.content = content;
  }

  $: if(content && reference && !initialized) {
      initialized = true;
      instance = tippy(reference,{...props,appendTo:domelement});
  }
  onMount(() => {

  });
  beforeUpdate(() => {
      if (  styleel && stylestring) {
          styleel.innerHTML = stylestring;
      }
  });




</script>

<style>
  .wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  span {
    padding: 16px;
    border-radius: 5px;
    background-color: blue;
    color: white;
    cursor: pointer;
  }

  :global(.tooltip) {
    color: orange;
    font-weight: bold;
    text-transform: uppercase;
  }
</style>

<!--- <slot use:tippy={props}></slot> --->
    <div bind:this={domelement} class="first">
        {#if domelement && content}
            <slot use:tippy={{...props,appendTo:domelement}}></slot>
        {/if}
    </div>

{#if stylestring}
    <style bind:this={styleel}/>
{/if}
